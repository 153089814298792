import { gql } from 'apollo-boost';

export const ON_BOARD = gql`
mutation onBoard($input:ProductServiceInput){
  onboard(input: $input) {
   status
    error
    data {
      id
    }
  }
}
`;