import React, { useCallback, useState } from 'react';
import axios from "axios";
import { useMutation } from '@apollo/react-hooks';
import { config } from '../config';
import { Card, FormLayout, Layout, Page, PageActions } from '@shopify/polaris';
import { TimezonePicker } from "baseui/timezonepicker";
import { getHoursFromMilliSecsUser, useStateHandler } from '../core';
import { TimeZone, ServiceSelect, FormFields, OperatingDetails } from '../users';
import { ON_BOARD } from '../apollo/mutations';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

export const OnBoarding = (props: any) => {
  let history = useHistory();
  const [onBoard, { data, loading, error }] = useMutation(ON_BOARD);
  const [active, setActive] = useState(false);
  const [serviceClicked, setServiceClicked] = useState(false);
  const [selectedService, setSelectedService] = useState([]);

  const [timeValue, setTimeValue] = useState('Europe/London');

  console.log(data);
  //all input value states updates
  const { state, dispatch } = useStateHandler({
    name: '',
    vendorName: '',
    capacity: '',
    location: '',
    sessionTime: '',
    sessionPrice: '',
    pricingUnit: '',
    sessionOffers: '',
    selectedDays: ['none'],
    selectHourOption: ['none'],
    startTime: 0,
    endTime: 0,
    // timeValue : 'Europe/London',
  });

  const toggleActive = useCallback(() => setActive(active => !active), []);

  const callingService = useCallback((value) => {
    setServiceClicked(!serviceClicked);
    setSelectedService(value);
    toggleActive();
  }, []);

  let sTime: number;
  let eTime: number;
  if (state.selectHourOption[0] === "twentyfour hours") {
    sTime = Number("00.00") * 60;
    eTime = Number("24.00") * 60;
  } else {
    sTime = Number(getHoursFromMilliSecsUser(state.startTime));
    eTime = Number(getHoursFromMilliSecsUser(state.endTime));
  }
  //remove 1st item i.e custom days from array\\>>and
  //to set hours for only selected custom days\\
  let cusDays: any = {};
  state.selectedDays.filter((item: any) => {
    if (item !== "custom days") {
      cusDays[item] = { "open": sTime, "close": eTime };
    };
    return cusDays;
  });
  // hours if 7 days else only selected cus days \\
  const hour = (state.selectedDays[0] === "seven days") ? {
    "mon": { "open": sTime, "close": eTime },
    "tue": { "open": sTime, "close": eTime },
    "wed": { "open": sTime, "close": eTime },
    "thu": { "open": sTime, "close": eTime },
    "fri": { "open": sTime, "close": eTime },
    "sat": { "open": sTime, "close": eTime },
    "sun": { "open": sTime, "close": eTime }
  } : cusDays;

  const submitHandler = async () => {
    const { data: res } = await onBoard({
      variables: {
        input: {
          name: state.name,
          vendorName: state.vendorName,
          capacity: Number(state.capacity),
          vendorId: "vendorId1",
          hour: hour,
          type: selectedService[0],
          sessionDuration: Number(state.sessionTime),
          slotByDay: false,
          price: Number(state.sessionPrice),
          pricingUnit: Number(state.pricingUnit),
          location: state.location
        }
      }
    });
    if (res) {
      toast.success('Your service has been onboarded successfully');
      history.push('/list')
    }
    else {
      toast.error('cannot create the service');
    }
  };

  const onChangeHandler = (key: any, value: any) => {
    dispatch({ [key]: value });
  };
  // to choose custom or whole week\\
  const handleCustomDayChange = useCallback((value: any) => {
    dispatch({ selectedDays: value });
  }, []);
  // to choose 24hrz or custom hrz\\
  const handleChoiceHourListChange = useCallback(value => {
    dispatch({ selectHourOption: value });
  }, []);
  const handleSTimeChange = (sTime: number) => {
    dispatch({ startTime: sTime });
  };
  const handleETimeChange = (eTime: number) => {
    dispatch({ endTime: eTime });
  };

  return (
    <Page>
      <Layout.AnnotatedSection
        title="Slot Booking"
        description="General infomration about a Service Product"
      >
        <Card
          title="Availability information"
          actions={[{ content: 'Learn more' }]}
        >
          <Card.Section>
            <FormLayout>
              <ServiceSelect
                active={active}
                serviceClicked={serviceClicked}
                selectedService={selectedService}
                callingService={callingService}
                toggleActive={toggleActive}

              />
              {serviceClicked ? (
                <Card>
                  {(selectedService[0] === "virtual" || selectedService[0] === "both") &&
                    (
                      <Card.Section>
                        <TimeZone
                          value={state.timeValue}
                          onChangeTimeHandler={({ id }: any) => {
                            console.log(id, 'iddddddd', timeValue);
                            setTimeValue(id);
                          }
                          }
                        />
                      </Card.Section>
                    )
                  }

                  <Card.Section title={`${selectedService} Service Slots Availability`}>
                    <OperatingDetails
                      selected={state.selectedDays}
                      handleCustomDayChange={handleCustomDayChange}
                      handleChoiceHourListChange={handleChoiceHourListChange}
                      selectHourOption={state.selectHourOption}
                      handleSTimeChange={handleSTimeChange}
                      handleETimeChange={handleETimeChange}
                      startTime={state.startTime}
                      endTime={state.endTime}
                    />
                  </Card.Section>
                  <FormFields
                    state={state}
                    onChangeHandler={onChangeHandler}
                  />
                </Card>
              ) : null}
              <PageActions
                primaryAction={{
                  content: 'Submit',
                  onAction: () => submitHandler(),
                }}
                secondaryActions={[
                  {
                    content: 'Dismiss',
                    onAction: () => setServiceClicked(false),
                  },
                ]}
              />
            </FormLayout>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </Page>
  );
}
