import React from 'react';
import { Banner as PolarisBanner } from '@shopify/polaris';

export const Banner = ({
  id,
  status,
  title,
  onDismiss,
  action,
  children
 }: any) => {
  return (
    <span id={id}>
      <PolarisBanner
        status={status}
        title={title}
        onDismiss={onDismiss}
        action={action}>
        {children}
      </PolarisBanner>
    </span>
  );
};