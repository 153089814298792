import React from 'react';
import { Card, TextField } from '@shopify/polaris';

export const FormFields = ({state, onChangeHandler}:any) => {

    return (
        <>
        <Card.Section title="Service Details">
            <TextField
                label="Name"
                value={state.name}
                helpText="add your business name."
                onChange={newVal => onChangeHandler('name', newVal)}
            />
            <br />
            <TextField
                label="Vendor Name"
                value={state.vendorName}
                helpText="add your business name to book slots under."
                onChange={newVal => onChangeHandler('vendorName', newVal)}
            />
            <br />
            <TextField
                label="Capacity"
                value={state.capacity}
                placeholder="enter capacity number value"
                helpText="We’ll use this to check capacity ."
                onChange={newVal => onChangeHandler('capacity', newVal)}
            />
            <br />
            <TextField
                label="Location"
                value={state.location}
                helpText="add where is the service location."
                onChange={newVal => onChangeHandler('location', newVal)}
            />  
            <br /> 
            <TextField
                label="Session Duration"
                value={state.sessionTime}
                helpText="add how we you want to set your session in mins"
                onChange={newVal => onChangeHandler('sessionTime', newVal)}
            />
        </Card.Section>
        <Card.Section title="Service Pricing">
            <TextField
                label="session cost"
                value={state.sessionPrice}
                helpText="add how you want to price your session in £ "
                onChange={newVal => onChangeHandler('sessionPrice', newVal)}
            />
            <br />
            <TextField
                label="cost per unit"
                value={state.pricingUnit}
                helpText="add if any discounts to be added (optional) "
                onChange={newVal => onChangeHandler('pricingUnit', newVal)}
            />
            <br />
            <TextField
                label="Offers"
                value={state.sessionOffers}
                helpText="add if any offers to be added (optional) "
                onChange={newVal => onChangeHandler('sessionOffers', newVal)}
            />
        </Card.Section>
        </>
    );
}