import React, { useState, useRef, useEffect, useCallback, FC } from 'react';
import { Modal, Stack, InlineError } from '@shopify/polaris';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import {
    getCurrentDateTime, getDate, getTime, getSessionMins, TimeSlotsStyles, useSlots,
    getHoursFromMilliSecs, SlotsComp, useStateHandler, Slot
} from '../';


export const MultiSingleSlot: FC<any> = ({ loading, error, service, selectedDates, orders, ...props }) => {
    const [bookingActivation, setBookingActivation] = useState<boolean>(true);
    const [selectedTime, setSelectedTime] = useState<string>();
    const [inputVal, setInputVal] = useState<number>(1);
    const [serviceNotAvailableFor, setServiceNotAvailableFor] = useState<string>("");
    const [isCurrentTimePass, setIsCurrentTimePass] = useState<string>("");
    const [currentSlot, setCurrentSlot] = useState<object | any>({});
    let stateSlots = useRef({
        slots: [{ time: 0, capacity: 0 }]
    });
    const { state, dispatch } = useStateHandler({
        allDayChecked: false,
        capacity: 1
    });
    const [slots, setSlots] = useState(stateSlots.current.slots);
    stateSlots.current = useSlots({
        service,
        selectedDates,
        orders
    });

    useEffect(() => {
        setSlots(stateSlots.current.slots);
    }, [stateSlots.current.slots]);

    const onChangeCapacity = (evt: any) => {
        dispatch({ capacity: evt.target.value });
    };

    const onCheckHandle = useCallback((newChecked: boolean) => dispatch({ allDayChecked: newChecked }), [dispatch]);

    const onSubmit = () => {
        setInputVal(0);
        const time = ((moment(selectedTime).hours() * 60) + moment(selectedTime).minutes()) + service.duration;
        const { capacity } = state;
        const { start, end } = selectedDates;
        const endTime = getTime(time, end);
        console.log('endTime', endTime)
        console.log('selectedTime', selectedTime)
        // console.log('startTime', startTime)
        console.log('time', time)
        // const sTime = getTime((startTime / 60), start);
        // const eTime = getTime((endTime / 60), end);
        // const duration = getSessionMins(sTime, eTime);
        // console.log('eTime', eTime)

        props.onAction({
            startTime: selectedTime,
            endTime: endTime,
            selectedDates: { start: getDate(start), end: getDate(end) },
            capacity,
            // sessionMinutes: duration
        }, inputVal);
    };

    const onTimeChange = (endTime?: any) => (time: any) => {
        (endTime) ? dispatch({ endTime: time }) : dispatch({ startTime: time });
    };

    const onClickHandler = (timeSelected: number) => () => {
        const date = getTime(timeSelected, selectedDates.start);
        const selectedDate = getDate(selectedDates.start);
        const { currentDate = "", currentMinutes = 0, currentHour = 0 } = getCurrentDateTime();
        if (selectedDate === currentDate) {
            currentDateNow(timeSelected, currentMinutes, currentHour, currentDate);
        }
        setCurrentSlot({ [timeSelected]: true });
        setBookingActivation(!inputVal);
        setSelectedTime(date);
    };

    const currentDateNow = (timeSelected: number, currentMinutes: number, currentHour: number, currentDate: string) => {
        const currentHourInMinutes = currentHour * 60 + currentMinutes;
        if (currentHourInMinutes > timeSelected) {
            setIsCurrentTimePass(`Slot time is already passed for ${currentDate}`);
        } else {
            setIsCurrentTimePass('');
        }
    }

    useEffect(() => {
        const { hour } = service || {};
        const { start, end } = selectedDates;
        const hours = { ...hour };

        const bookingEndDate = new Date(end);
        let bookingStartDate = new Date(start);

        Object.keys(hours).forEach(key => {
            if (hours[key] === null) {
                delete hours[key];
            }
        });

        const workingDays = Object.keys(hours);
        const serviceNotAvailableDates: Array<Number> = [];

        while (bookingStartDate <= bookingEndDate) {
            const day = bookingStartDate.toLocaleString('en-us', { weekday: 'short' }).toLowerCase();
            const dayExist = workingDays.indexOf(day);
            if (dayExist === -1) {
                serviceNotAvailableDates.push(bookingStartDate.getDate());
            }
            bookingStartDate = new Date(bookingStartDate.getTime() + (24 * 60 * 60 * 1000));
        }
        const formatString = `${serviceNotAvailableDates.join(", ")} ${bookingStartDate.toLocaleString('default', { month: 'long' })} ${bookingStartDate.getFullYear()}`;
        serviceNotAvailableDates.length === 0 ? setServiceNotAvailableFor("") : setServiceNotAvailableFor(formatString);
    }, [loading]);

    const renderUnAvailability = () => {
        return <span>
            Service is not Available on <b> {serviceNotAvailableFor} </b>
        </span>
    }

    return (
        <div style={{ height: '500px' }}>
            <Modal
                open={props.active}
                onClose={props.onClose}
                loading={loading}
                title={`Booking Availability for ${selectedDates
                        ? `${moment(selectedDates.start).format('ll')} - ${moment(selectedDates.end).format('ll')}`
                        : ''
                    }`}
                primaryAction={{
                    content: 'Book your slot',
                    onAction: onSubmit,
                    disabled: bookingActivation  //: TODO, figure out disabled options..
                }}
                size={'Large'}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: props.onClose
                    }
                ]}
            >
                <Modal.Section>
                    {slots.length !== 0 ? <TimeSlotsStyles>
                        {/* <h4> {serviceNotAvailableFor ? renderUnAvailability() : ''} </h4> */}
                        <Stack>
                            {slots.map((slot: Slot) => {
                                const hr = Math.floor(slot.time / 60);
                                const min = slot.time % 60;
                                return (
                                    <div key={slot.time}>
                                        <Button
                                            id={`button_${slot.time}`}
                                            key={slot.time}
                                            size="lg"
                                            variant={
                                                slot.capacity === 0 || slot.capacity < 0
                                                    ? 'outline-danger'
                                                    : 'outline-success'
                                            }
                                            onClick={onClickHandler(slot.time)}
                                            disabled={slot.capacity === 0 || slot.capacity < 0}>
                                            {hr < 10 ? '0' + hr : hr} :{' '}
                                            {min < 10 ? '0' + min : min}
                                            <br />
                                            <span>{slot.capacity <= 0 ? 0 : slot.capacity} slots available</span>
                                        </Button>
                                        { (currentSlot && currentSlot[slot.time]) && <InlineError message={isCurrentTimePass} fieldID={`button_${slot.time}`} />}
                                    </div>
                                );
                            })}
                            {
                                // :TODO set error in parent component, on each modal opening..
                                // (error) && <Banner status={'critical'} title={`Your request cannot be completed at this stage.`}/>
                            }
                        </Stack>
                    </TimeSlotsStyles> : <h2>The service is not available on {moment(selectedDates.start).format('ll')}, please try to book for any other start date.</h2>}
                </Modal.Section>
            </Modal>
        </div>
    );
};
