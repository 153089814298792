import Talk from "talkjs";

class ChatHelper {
  constructor() {
    this.sellerRole = "operator";
    this.vendorRole = "vendor";
  }
  // TalkJS

  getTalkJSUser = ({ id, name, email, role }) => {
    const user = {
      id,
      name,
      email,
      role,
    };
    console.log("Talk--", Talk);
    return new Talk.User(user);
  };

  createTalkJSConversion = (talkSession, talkCurrentUser, other) => {
    if (talkSession) {
      const conversation = talkSession.getOrCreateConversation(Talk.oneOnOneId(talkCurrentUser, other));
      conversation.setParticipant(talkCurrentUser);
      conversation.setParticipant(other);
      conversation.setAttributes({ custom: { role: "operator" } });
      return conversation;
    }
    return null;
  };

  createTalkJSGroupConversion = (talkSession, { vendor, customer, seller, subject }) => {
    if (talkSession) {
      const conversation = talkSession.getOrCreateConversation(Talk.oneOnOneId(vendor, customer));

      conversation.setParticipant(vendor);
      conversation.setParticipant(customer);
      conversation.setParticipant(seller);
      conversation.setAttributes({
        subject,
      });

      return conversation;
    }
    return null;
  };

  createTalkJSPopup = (talkSession, conversation) => {
    if (talkSession) {
      const popup = talkSession.createPopup(conversation, { keepOpen: false });
      popup.mount({ show: false });
      return popup;
    }
    return null;
  };

  createTalkJSSession = (me) => {
    return new Talk.Session({
      appId: "twLyn6PC",
      me,
    });
  };

  createTalkJSInbox = (talkSession) => talkSession && talkSession.createInbox();

  destroyTalkJSSession = (talkSession) => talkSession && talkSession.destroy();
}

export default new ChatHelper();
