import React, { useState, useEffect } from 'react';
import queryString from 'query-string'
import { useLocation } from 'react-router';
import { Form, Page, FormLayout, TextField, Button } from '@shopify/polaris';
import { ApiQuery, Banner } from '../core';
import axios from 'axios';
import { config } from '../config';
import { url } from 'inspector';

export const Quote = () => {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const { data: resData }: any = queryString.parse(location.search);
    const { css = "{}" } = queryParams;
    const parseCss: any = css;
    const cssToApply = JSON.parse(parseCss);
    const { bg = "", color = "" } = cssToApply;
    const userData = JSON.parse(resData);
    const [customer_email, setCustomer_Email] = useState(userData.customerEmail || "");
    const [isLoggedIn, setIsLoggedIn] = useState(!!userData.customerEmail);
    const [message, setMessage] = useState("");
    const [price, setPrice] = useState(userData.productPrice || "");
    const [firstName, setFirstName] = useState(userData.firstName || "");
    const [isSubmit, setIsSubmit] = useState(true);
    const [isMessage, setIsMessage] = useState(userData.isMessage);
    const [lastName, setLastName] = useState(userData.lastName || "");
    const [customer_name, setCustomer_name] = useState(userData.customerName || "");
    const [place, setPlace] = useState(userData.place || "");
    const [shop_domain, setShop_Domain] = useState(userData.shopName || "");
    const [shopifyCustomerId, setShopifyCustomerId] = useState(userData.customerId || "");
    const [shopifyProductId, setShopifyProductId] = useState(userData.productID || "");
    const [shopifyVariantId, setShopifyVariantId] = useState(userData.variantID || "");
    const [selectedDate, setSelectedDate] = useState(userData.date || "");
    const [loading, setLoading] = useState(true);
    const [banner, setBanner] = useState({
        title: "",
        status: "",
    });
    const [hasBanner, setHasBanner] = useState(false);

    const handleLoading = () => {
        setLoading(false);
    }

    useEffect(() => {
        window.addEventListener("load", handleLoading);
        return () => window.removeEventListener("load", handleLoading);
    }, []);

    const validateEmail = (email: any) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    const handleSubmit: any = async (_event: any) => {
        if (!isLoggedIn && !firstName) {
            setHasBanner(true);
            setBanner({ status: 'critical', title: "Please provide your first name to request a quote." });
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            return false;
        }
        if (!isLoggedIn && !lastName) {
            setHasBanner(true);
            setBanner({ status: 'critical', title: "Please provide your last name to request a quote." });
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            return false;
        }
        if (!isLoggedIn && !customer_email) {
            setHasBanner(true);
            setBanner({ status: 'critical', title: "Please provide email to request a quote." });
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            return false;
        }
        if (!isLoggedIn && !validateEmail(customer_email)) {
            setHasBanner(true);
            setBanner({ status: 'critical', title: "Please provide valid email to request a quote." });
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            return false;
        }
        if (!isMessage && !place) {
            setHasBanner(true);
            setBanner({ status: 'critical', title: "Please tell us the location or venue for your event." });
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            return;
        }
        if (!isMessage && !selectedDate) {
            setHasBanner(true);
            setBanner({ status: 'critical', title: "Please tell us the date for your event." });
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            return;
        }
        if (!message || message.trim() === "") {
            setHasBanner(true);
            setBanner({ status: 'critical', title: "Please provide some details regarding your quote request in message box." });
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            return;
        }
        await postData();
    };

    const data = {
        customer_email,
        customer_name: customer_name || `${firstName || ""}${lastName ? ` ${lastName}` : ""}`,
        firstName,
        lastName,
        isMessage,
        message,
        location: place,
        price,
        date: selectedDate,
        shop_domain,
        shopifyCustomerId,
        shopifyProductId,
        shopifyVariantId
    };

    if (!shopifyCustomerId) {
        delete data.shopifyCustomerId;
    }

    const postData = async () => {
        try {
            const res: any = await axios({
                method: 'POST',
                url: `${config.slotServiceUrl}/api/service/quote`,
                data,
            })

            if (res.data) {
                setHasBanner(true);
                setBanner({ status: 'success', title: "Thankyou for contacting us, our vendor will reach you shortly." });
                setIsSubmit(false);
            }
        } catch (error: any) {
            const { response: { data: { error: resError = "" } = {} } = {} } = error;
            setHasBanner(true);
            setBanner({ status: 'critical', title: resError });
        }
    };

    const onDismissBanner = () => {
        setHasBanner(false);
        setIsSubmit(true);
    };

    const getTitle:any = () => (
        <div style={{
            textAlign: "center",
            fontFamily: "Homemade Apple",
        }}>
            {isMessage ? "Chat with supplier" : "Request for a quote"}
        </div>
    );

    return (
        <ApiQuery loading={loading}>
            <Page title={getTitle()}>
                {
                    hasBanner ? <Banner status={banner.status} title={banner.title} onDismiss={onDismissBanner} /> : null
                }
                <br />
                {isSubmit && <Form onSubmit={handleSubmit}>
                    <FormLayout>
                        <TextField
                            value={firstName || ""}
                            onChange={(value) => {
                                setFirstName(value);
                            }}
                            label="First Name*"
                            type="text"
                            disabled={isLoggedIn}
                        />
                        <TextField
                            value={lastName || ""}
                            onChange={(value) => {
                                setLastName(value);
                            }}
                            label="Last Name*"
                            type="text"
                            disabled={isLoggedIn}
                        />
                        <TextField
                            value={customer_email || ""}
                            onChange={(value) => {
                                setCustomer_Email(value);
                            }}
                            label="Email*"
                            type="email"
                            disabled={isLoggedIn}
                        />
                        <TextField
                            value={place || ""}
                            onChange={(value) => {
                                setPlace(value);
                            }}
                            label={isMessage ? "Please indicate the location or venue for your event" : "Please tell us the location or venue for your event*"}
                            type="text"
                        />
                        <TextField
                            value={selectedDate || ""}
                            onChange={(value) => {
                                setSelectedDate(value);
                            }}
                            label={isMessage ? "Please indicate the date for your event" : "Please tell us the date for your event*"}
                            type="date"
                        />
                        <TextField
                            value={message || ""}
                            onChange={(value) => {
                                setMessage(value);
                            }}
                            multiline={4}
                            label="Message*"
                            type="text"
                        />
                        <Button id={bg} submit>Submit</Button>
                        <div style={{
                            paddingBottom: "40px"
                        }} />
                    </FormLayout>
                </Form>}
            </Page>
        </ApiQuery>
    );
}