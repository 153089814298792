import React, { Component } from "react";
import { EmptyState } from '@shopify/polaris';
import { Banner } from '.';

export class ErrorBoundary extends Component {
  state: {error: false, errorInfo: ''};
  constructor(props: any) {
    super(props);
    this.state = { error: false, errorInfo: '' };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    // :TODO use relevant state props if wants to show specific error msgs
    if (this.state.error) {
      return <Banner status={'critical'} title={`Something has gone wrong with your request!`}/>;
    }

    return this.props.children;
  }
}
