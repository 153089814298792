import { gql } from 'apollo-boost';

export const GET_ORDERS_BY_DAY = gql`
  query getBookingByDay($input: GetBookingInput!) {
    getBookingByDay(input: $input){
      data {
        _id
        duration
        endDate
        endTime
        expiresIn
        personCount
        serviceId
        startDate
        startTime
        status
        token
        updatedAt
        createdAt
      }
      status
      error
    }
}`;