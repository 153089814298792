import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { Page, Banner, FormLayout, TextField, Layout, PageActions } from '@shopify/polaris';
import { ApiQuery } from '../core';
import Talk from 'talkjs';
import axios from 'axios';

import { config } from '../config';

// import helpers
import chatHelper from '../helpers/chat';

export const Chat = () => {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const { data: resData }: any = queryString.parse(location.search);

    const { css = "{}" } = queryParams;
    const parseCss: any = css;
    const cssToApply = JSON.parse(parseCss);
    const { bg = "", color = "" } = cssToApply;

    const userData = JSON.parse(resData);

    const [customerEmail, setCustomerEmail] = useState(userData.customerEmail || "");
    const [customerName, setCustomerName] = useState(userData.customerName || "");
    const [isChatEnabled, setIsChatEnabled] = useState(!!userData.customerEmail);
    const [errMessage, setErrMessage] = useState("");

    const [loading, setLoading] = useState(false);

    const validateEmail = (email: any) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    const initiateChat = () => {
        if (!isChatEnabled) {
            if (!customerName) {
                setErrMessage("Please provide your name to start chatting with supplier.");
                return false;
            }
            if (!customerEmail) {
                setErrMessage("Please provide email to start chatting with supplier.");
                return false;
            }
            if (!validateEmail(customerEmail)) {
                setErrMessage("Please provide valid email to start chatting with supplier.");
                return false;
            }
        }
        setErrMessage("");
        setIsChatEnabled(true);
        setLoading(true);
        axios({
            method: 'GET',
            url: `${config.slotServiceUrl}/api/service/quote/info`,
            params: {
                shop_domain: userData.shopName || "",
                shopifyProductId: userData.productID || "",
            },
        })
        .then(res => {
            const { seller, vendor } = (res.data && res.data.data) || {};

            const customerData = {
                id: customerEmail || "",
                name: customerName || "",
                email: customerEmail || "",
                role: "customer",
            };

            const operatorData = {
                id: seller.id,
                name: seller.name,
                email: seller.email,
                role: seller.role,
            };

            const vendorData = {
                id: vendor.id,
                name: vendor.name,
                email: vendor.email,
                role: vendor.role,
            };

            Talk.ready.then(() => {
                setLoading(false);

                const customer = chatHelper.getTalkJSUser(customerData);
                const seller = chatHelper.getTalkJSUser(operatorData);
                const vendor = chatHelper.getTalkJSUser(vendorData);

                const talkSession = chatHelper.createTalkJSSession(customer);
        
                const conversation = chatHelper.createTalkJSGroupConversion(talkSession, { vendor, customer, seller, subject: userData.productTitle || "Service chat" });
                // chatHelper.createTalkJSPopup(talkSession, conversation);

                const inbox = chatHelper.createTalkJSInbox(talkSession);
                inbox.select(conversation);
                inbox.mount(document.getElementById('chatbox-container'));
            });
        })
        .catch(err => {
            console.log("err--", err);
        });
    };

    useEffect(() => {
        if (userData.customerEmail) {
            initiateChat();
        }
    }, []);

    const getTitle:any = () => (
        <div style={{
            textAlign: "center",
            fontFamily: "Homemade Apple",
        }}>
            {"Chat with supplier"}
        </div>
    );

    if (!isChatEnabled) {
        return (
            <Page
                title={getTitle()}
            >
                <Layout>
                    <Layout.Section>
                        <Banner
                            title="Please provide us with a name and an email to initiate chat so that we can notify you when the supplier replies. Messages are sent instantly to the supplier and they should reply within 72 hours."
                            status="info"
                        />
                    </Layout.Section>
                    {errMessage && (
                        <Layout.Section>
                            <Banner
                                title={errMessage}
                                status="warning"
                            />
                        </Layout.Section>
                    )}
                    <Layout.Section>
                        <FormLayout>
                            <TextField
                                type="text"
                                label="Your name*"
                                value={customerName}
                                onChange={(value) => {
                                    setErrMessage("");
                                    setCustomerName(value);
                                }}
                                autoComplete="off"
                            />
                            <TextField
                                type="email"
                                label="Your email*"
                                value={customerEmail}
                                onChange={(value) => {
                                    setErrMessage("");
                                    setCustomerEmail(value);
                                }}
                                autoComplete="email"
                            />
                        </FormLayout>
                    </Layout.Section>
                </Layout>
                <PageActions
                    primaryAction={{
                        id: bg,
                        content: "Continue chat",
                        onAction: () => initiateChat(),
                        
                    }}
                />
            </Page>
        )
    }

    return (
        <ApiQuery loading={loading}>
            <Page title={getTitle()}>
                <br />
                <div id="chatbox-container" className="chatbox-container" />
            </Page>
        </ApiQuery>
    );
}
