import styled from 'styled-components';

export const SlotBookerContainer = styled.div`
  button.btn-lg {
    margin: 1rem;
  }
  h2 {
    color: #007bff;
    text-align: center;
  }
`;

export const CalendarSlots = styled.div`
  width: 100%;
  height: auto;
  margin:0 auto;
  padding: 10%;
  position: absolute;
  top: 12%;
`;

export const HoursSelectStyles = styled.div `
width: fit-content;
`;

export const SpinnerStyle = styled.div `
display: flex;
justify-content: center;
`;