import React, { FC, useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_ORDER } from '../apollo';
import { CalendarDates, dateToIso, MultiSingleSlot, MultiRangeSlot, ProductAsService, TimeSlots } from '../core';
import { toast } from 'react-toastify';

interface Props {
  active: boolean;
  background: any,
  color:any,
  handleModalChange: () => void;
  numberOfDays: number;
  orderLoading: boolean,
  orders: any;
  selectedDates: CalendarDates;
  service: ProductAsService;
}

interface SlotData {
  selectedTime: string;
  startDate: string;
  endTime: string;
}

interface RangeData {
  startTime: string;
  endTime: string;
  selectedDates: { start: string, end: string };
  capacity: number;
  sessionMinutes: number;
}

export const Slots: FC<Props> = ({
  active,
  background,
  color,
  orderLoading,
  handleModalChange,
  service,
  orders,
  selectedDates,
  numberOfDays,
  ...rest
}: Props) => {
  const [createOrderItem, { loading, error }] = useMutation(CREATE_ORDER);
  const { start, end } = selectedDates;
  const [singleDay, setSingleDay] = useState(dateToIso(start) === dateToIso(end));

  useEffect(() => {
    setSingleDay(dateToIso(start) === dateToIso(end));
  }, [start, end]);

  const handleClose = () => {
    handleModalChange();
  };

  const createOrder = ({
    startDate,
    endDate,
    startTime,
    endTime,
    capacity,
    sessionMinutes
  }: any, onMultiRange?: boolean) => {

    const {
      type,
      _id: slotServiceId,
      // duration: slotDuration
    } = service;

    if (!onMultiRange) {
      endDate = startDate;
    }

    let data = createOrderItem({
      variables: {
        input: {
          personCount: +capacity,
          // duration:slotDuration,
          endDate,
          endTime,
          expiresIn: 30, // confirm from business
          serviceId: slotServiceId,
          startDate,
          startTime,
          status: 'inProgress',
          token: localStorage.getItem('token'),
          // quantity: capacity * numberOfDays,
        }
      }
    });
    data.then(response => {
      handleClose();
      toast.success("Your provisional booking has been created successfully!");
    }).catch(err => {
      toast.error("Something Went Wrong!");
    })
  };

  const onSlotBooking = async (args: SlotData, capacity: number) => {
    const { selectedTime: startTime, ...rest } = args;
    await createOrder({ ...rest, startTime, capacity });
  };

  const onRangeBooking = async (data: RangeData) => {
    const { selectedDates: { start: startDate, end: endDate }, ...rest } = data;

    await createOrder({ ...rest, startDate, endDate }, true);
  };

  return (
    <div>
      {singleDay && !service.slotByDay &&
        <TimeSlots
          active={active}
          background={background}
          color={color}
          error={error}
          loading={loading}
          onAction={onSlotBooking}
          onClose={handleClose}
          orderLoading={orderLoading}
          orders={orders}
          selectedDates={selectedDates}
          service={service}
          size={'Large'} />
      }
      {!singleDay &&
        <MultiSingleSlot
          active={active}
          error={error}
          loading={loading}
          onAction={onRangeBooking}
          onClose={handleClose}
          orders={orders} 
          selectedDates={selectedDates}
          service={service}
          size={'Large'} />
      }
      {
        service.slotByDay &&
        <MultiSingleSlot
          active={active}
          error={error}
          loading={loading}
          onAction={onRangeBooking}
          onClose={handleClose}
          orders={orders} 
          selectedDates={selectedDates}
          service={service}
          size={'Large'}
          />
      }
    </div>
  );
};
