import React from 'react';
import { Card,Subheading,ChoiceList } from '@shopify/polaris';
import TimePicker from 'react-bootstrap-time-picker';

import {HoursSelectStyles} from '../core/component/styles/slotBookerStyles';

export const OperatingDetails = ({
  selected,
  handleCustomDayChange,
  handleChoiceHourListChange,
  selectHourOption,
  handleSTimeChange,
  handleETimeChange,
  startTime,
  endTime,
}: any) => {
  const renderChildrenDays = (selectedVal: boolean) => {
    return selectedVal && (
      <ChoiceList
        title="Select days you would like services for :"
        choices={[
          { label: "Monday", value: "mon"},
          { label: "tuesday", value: "tue"},
          { label: "Wednesday", value: "wed"},
          { label: "thursday", value: "thu"},
          { label: "friday", value: "fri"},
          { label: "Saturday", value: "sat" },
          { label: "Sunday", value: "sun"}
        ]}
        selected={selected}
        onChange={handleCustomDayChange}
        allowMultiple={true}
      />
    )
  };
  const renderChildrenHours = (selectHourOption: boolean) => {
    return selectHourOption && (
    <HoursSelectStyles>
      <p>Please select Start time</p>
      <TimePicker start="07:00" end="21:00" step={30} onChange={handleSTimeChange} value={startTime}/>
      <br />
      <p>Please select End time</p>
      <TimePicker start="07:00" end="21:00" step={30} onChange={handleETimeChange} value={endTime}/>
    </HoursSelectStyles>
    );
  }
  return (
    <Card.Section>
      <Subheading>Operating Days</Subheading>
    <div>
      <ChoiceList
        title='days'
        choices={[
          { 
            label: "7 days a week",
            value: "seven days"
          },
          {
            label: "custom",
            value: "custom days",
            renderChildren: renderChildrenDays
          }
        ]}
        selected={selected}
        onChange={handleCustomDayChange}
      />
    </div>

      <Subheading>Operating Hours</Subheading>
      <div>
      <ChoiceList
        title='hours'
        choices={[
          { label: "24 hours a day", value: "twentyfour hours" },
          {
            label: "custom",
            value: "custom hours",
            renderChildren: renderChildrenHours
          }
        ]}
        selected={selectHourOption}
        onChange={handleChoiceHourListChange}
      />
    </div>
    </Card.Section>
  );
};
