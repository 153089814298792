import React, { ReactElement } from 'react';
import { Loader } from './';

export interface Props {
  loading: boolean;
  error?: string;
  children?: any;
}

export const ApiQuery = ({ loading, error, children }: Props) => {
  if (loading) {
    return (
     <Loader isFullPage/>
    );
  }

  if (error) {
    throw new Error(error);
  }

  return children as ReactElement<any>;
};