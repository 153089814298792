import React, { useEffect, useState } from 'react';
import { Card, DataTable, Page } from '@shopify/polaris';
// import ReactPaginate from "react-paginate";
import { useQuery } from '@apollo/react-hooks';
import { GET_ALL_PRODUCT } from '../apollo';
import { geResponseMapper, useStateHandler, ProductAsService } from '../core';
import SearchBox from '../core/component/searchBox';


interface State {
    state: {
        loading: true,
        productAsService: ProductAsService,
        services: any[],
        // perPage: number,
        // activePage: number,
        // offset: number,
        // open: boolean,
        // pageCount: number
    };
    dispatch: any;
}

const initialState = {
    loading: true,
    productAsService: null,
    services: [],
    // perPage: 5,
    // activePage: 0,
    // offset: 0,
    // open: false,
    // pageCount: 0,
};

export const OnBoardingList = () => {
    const { state, dispatch }: State = useStateHandler(initialState);
    const [searchQuery, setSearchQuery] = useState("");
    const [rows, setRows] = useState([]);
    const [initialServices, setInitialServices] = useState([]);
    const { loading, error, data }: any = useQuery(GET_ALL_PRODUCT);

    useEffect(() => {
        const services = geResponseMapper(data, 'getProductsService');
        setInitialServices(services)
        if (services) {
            dispatch({
                services,
                // pageCount: Math.ceil(services.length / state.perPage)
            });
        }
    }, [data, dispatch]);

    useEffect(() => {
        const { services } = state;
        if (services) {
            var output = services.map(function (obj) {
                return Object.keys(obj).map(function (key) {
                    return obj[key];
                });
            });
            const rowsUpdate: any = output.reverse()
            setRows(rowsUpdate);
        }
    }, [state, dispatch]);


    const searchResult = (query: string) => {
        if (query) {
            let updatedService = [...initialServices];
            let filtered = updatedService.filter((m: any) =>
                m.name.toLowerCase().startsWith(query.toLowerCase())
            );
            let services = filtered
            dispatch({
                services,
            });
        }
        else {
            const services = initialServices;
            dispatch({
                services,
            });
        }
    }
    const handleSearch = (query: string) => {
        setSearchQuery(query)
        if (query) {
            searchResult(query)
        }
        else {
            searchResult("")
        }
    };


    // const handlePageClick = (pageData: any) => {
    //     console.log("data", pageData);
    //     console.log("data", state);
    //     let selected = pageData.selected;
    //     let offset = Math.ceil(selected * state.perPage);
    //     dispatch({ offset: offset, activePage: selected });
    //     // , () => {
    //     // this.handleGet(selected + 1);
    // };
    // /****************** END Pagination Events ********************/

    return (
        <Page title="Services">
            <SearchBox value={searchQuery} onChange={handleSearch} />
            <Card>
                <DataTable
                    columnContentTypes={[

                    ]}
                    headings={[
                        'Name',
                        'Vendor name',
                        'Date',
                    ]}
                    rows={rows}
                />
            </Card>
            {/* {rows.length !== 0 && (
                <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination custom"}
                    // subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={state.activePage}
                />
            )} */}
        </Page>
    );
}