import styled from 'styled-components';

export const TimeSlotsStyles = styled.div`
.form-control {
  width: 50%;
  margin: 0 auto;
}
.Polaris-Stack {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
button.btn-outline-success:focus {
    background-color: #28a745;
    color: #fff;
}
button.btn-outline-danger {
    padding-right: 1.3rem;
    padding-left: 1.3rem;
}
`;

