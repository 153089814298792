import React from 'react';
import { Route } from 'react-router-dom';

import { ErrorBoundary } from '../component';

export const AppRoute = ({path, component: Component, ...rest}: any) => {
  return(
    <Route
      path={path}
      render={() => (
        <ErrorBoundary {...rest}>
          <Component {...rest}/>
        </ErrorBoundary>
    )}/>
  );
};