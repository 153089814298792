import React from 'react';
import { AppRoute } from './route';
import { Chat, SlotBooker, ToastBooker, OnBoardingList, Quote } from '../../pages';
import { OnBoarding } from '../../users';


const routes = [
  {
    path: '/slotbooking/:id',
    name: '',
    component: SlotBooker
  },
  {
    path: '/toastbooker',
    name: '',
    component: ToastBooker
  },
  {
    path: '/onboard',
    name: '',
    component: OnBoarding
  },
  {
    path: '/list',
    name: '',
    component: OnBoardingList
  },
  {
    path: '/quote',
    name: '',
    component: Quote
  },
  {
    path: '/chat',
    name: '',
    component: Chat,
  }
];

export const appRoutes = routes.map((route: any) => (
  <AppRoute {...route} key={route.path} />
));