import React, { FC, useEffect, useCallback } from 'react';
import { Heading } from '@shopify/polaris';
import { RouteComponentProps } from 'react-router';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import  queryString from "query-string";

import { McCalendar, Slots } from './';
import {
  ApiQuery,
  Banner,
  getDate,
  useStateHandler,
  SlotBookerContainer,
  CalendarSlots,
  Order,
  ProductAsService,
  Params,
  CalendarDates,
  geResponseMapper,
  getDateDiff
} from '../core';
import { GET_PRODUCT, GET_ORDERS_BY_DAY } from '../apollo';

interface State {
  state: {
    active: boolean;
    hasBanner: boolean;
    loading: boolean;
    productAsService: ProductAsService;
    orders: Order[];
    selectedDates: CalendarDates;
    numberOfDays: number;
    queryParams: Params;
  };
  dispatch: any;
}

// interface Params {
//   params: {
//     id: string;
//   };
// }
// interface ComputedMatch {
//   computedMatch: Params
// }

interface Props extends RouteComponentProps<any> { }

const initialState = {
  active: false,
  hasBanner: false,
  loading: true,
  productAsService: null,
  orders: [],
  selectedDates: {},
  numberOfDays: 0,
  productId: null
};

export const SlotBooker: FC<Props> = (computed: any) => {
  const { state, dispatch }: State = useStateHandler(initialState);

  const { params: { id: serviceId } } = computed.computedMatch;
  const { location } = computed;

  const queryParams = queryString.parse(location.search);

  const { css = "{}", token } = queryParams;
  const parseCss: any = css
  const userToken: any = token
  const cssToApply = JSON.parse(parseCss);
  const { bg = "", color= "" }= cssToApply;

  localStorage.setItem('token', userToken);

  const { loading, error, data }: any = useQuery(GET_PRODUCT, {
    variables: { input: { id: serviceId } }
  });

  const [getOrders, {loading: orderLoading, data: allOrders }] = useLazyQuery(GET_ORDERS_BY_DAY, { fetchPolicy: `network-only` });

  const orders = geResponseMapper(allOrders, 'getBookingByDay');
  useEffect(() => {
    const res = geResponseMapper(data, 'getService');
    if (res) {
      dispatch({ productAsService: res, hasBanner: false });
    }
    else {
      dispatch({ hasBanner: true });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (orders) {
      dispatch({ orders });
    }
  }, [orders, dispatch]);

  const handleModalChange = useCallback(() => dispatch({ active: !state.active }), [state.active, dispatch]);

  const onSelection = async (selectedDates: CalendarDates) => {
    getOrders({
      variables: {
        input: {
          serviceId: state.productAsService._id,
          startDate: getDate(selectedDates.start),
          endDate: getDate(selectedDates.end)
        }
      }
    });
    let daysDiff = getDateDiff(selectedDates.start, selectedDates.end)
    dispatch({
      selectedDates,
      active: !state.active,
      numberOfDays: daysDiff,
    });
  };

  return (
    // <ApiQuery loading={true} error={error}>
    <ApiQuery loading={loading} error={error}>
      <SlotBookerContainer>
        <Heading>Please request a quote for a more accurate availability schedule.</Heading>;
        {
          (state.productAsService) && (
            <CalendarSlots>
              <McCalendar background={bg} color={color} onSelection={onSelection}  />
              <Slots
                active={state.active}
                orderLoading={orderLoading}
                background={bg}
                color={color}
                selectedDates={state.selectedDates}
                numberOfDays={state.numberOfDays}
                service={state.productAsService}
                orders={state.orders}
                handleModalChange={handleModalChange}
              />
            </CalendarSlots>
          )
        }
        {
          (state.hasBanner) && <Banner status={'warning'} title={`No results found`} />
        }
      </SlotBookerContainer>
    </ApiQuery>
  );
};
