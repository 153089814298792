import React, { createContext, useContext, useReducer } from 'react';
import { initialState } from './state';


export const AppContext = createContext<any>(initialState);


export const StateProvider = ({reducer, state, children}: any) => {
  console.log('stateteee', state);
  return (<AppContext.Provider value={useReducer(reducer, state)}>
    { children }
    </AppContext.Provider>
  );
};

export const useStateValue = () => useContext(AppContext);