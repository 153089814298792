import styled from 'styled-components';

export const LoaderContainer = styled.div`
  .spinner {
    z-index: "99";
    text-align: center;
    color: #5968bf;
  }
  .full-page {
    z-index: 99;
    position: fixed;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #5968bf;
  }
`;
