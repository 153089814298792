import { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { AllOrders, Order } from '../core';
import * as helpers from '../core';

export interface SelectedDates {
  open: number;
  close: number;
}

export interface Hour {
  [key: string]: { open: number; close: number; };
}

export interface Slot {
  time: number;
  capacity: number;
}

export const useSlots = (data: any) => {
  const { selectedDates: { start }, service: { duration, hour, capacity: totalCapacity }, orders = [] } = data;

  const [allSlotOrders, setSlotOrders] = useState<AllOrders>([]);
  const [selectedDayTimes, setSelectedDayTimes] = useState<SelectedDates>({ close: 0, open: 0 });
  const [currentDateTime, setCurrentDateTime] = useState({ currentTime: "", currentDate: "", currentHour: 0, currentMinutes: 0 });
  const [slots, setSlots] = useState<Slot[]>([]);

  useEffect(() => {
    const { currentDate = "", currentTime = "", currentHour, currentMinutes } = helpers.getCurrentDateTime();
    setCurrentDateTime({ currentDate, currentTime, currentHour, currentMinutes });
  }, []);

  const getOrders = useCallback(() => {
    // console.log('orders', orders);
    return orders.reduce((allOrders: Order[], order: Order) => {
      // console.log('orders', orders)
      // console.log('allOrders', allOrders)
      if (order.sessionMinutes) {
        const numOfSlots = order.sessionMinutes / duration;

        const items = [...Array(numOfSlots)].fill(order).map((orderItem: Order, index: number) => {

          const startTime = (moment(orderItem.startTime).hours() * 60) + (duration * index);
          const endTime = startTime + duration;
          const newTimedOrder = {
            ...orderItem,
            startTime: helpers.getTime(startTime, orderItem.startDate),
            endTime: helpers.getTime(endTime, orderItem.startDate)
          };
          return newTimedOrder;
        });

        allOrders = [...allOrders, ...items];
      }
      else {
        allOrders.push(order);
      }

      return allOrders;
    }, []).reduce((allOrders: AllOrders, curr: Order) => {
      const hour: number = moment(curr.startTime).hours();
      const mins: number = moment(curr.startTime).minutes();
      const time: number = (hour * 60) + mins;

      if (allOrders[time]) {
        const updatedOrder = {
          ...allOrders[time],
          personCount: (allOrders[time].personCount + curr.personCount)
        };
        allOrders = { ...allOrders, [time]: { ...updatedOrder } };
      } else {
        allOrders[time] = curr;
      }

      return allOrders;
    }, {});
  }, [orders, duration]);

  useEffect(() => {
    setSlotOrders(getOrders());
  }, [getOrders]);

  const getDaySlots: any = useCallback(() => {
    const selectedDay = new Date(start).getDay();
    // eslint-disable-next-line array-callback-return
    Object.keys(hour).map((item, index: number) => {
      // console.log('selectedDayTimes', selectedDayTimes);
      // console.log('hours', hours);
      // console.log('item', item);
      // console.log('hours[item]', hours[item]);
      // console.log('index', index);
      // console.log('selectedDay', selectedDay);
      if (index === selectedDay) {
        setSelectedDayTimes(hour[item]);
      }
    });

    if (selectedDayTimes) {
      // tslint:disable-next-line: prefer-const
      let { open, close } = selectedDayTimes;
      // const numberOfSlots = Math.ceil((close - open) / duration) + 1; // re-check for an hours slot
      const numberOfSlots = Math.ceil((close - open) / duration); // re-check for an hours slot
      return [...Array(numberOfSlots)].fill(open).map((_, index) => (index === 0) ? open : open += (Number(duration)))
        .map((slot: any) => {
          // console.log('slot', slot)
          // console.log('allSlotOrders[slot]', allSlotOrders[slot])
          // console.log('totalCapacity', totalCapacity)
          // if (slot === 0) {
          //   return false;
          // }
          return {
            time: slot,
            capacity: (allSlotOrders[slot]) ? (totalCapacity - allSlotOrders[slot].personCount) : totalCapacity
          };
        });
    }
    else return [];
  }, [allSlotOrders, hour, start, totalCapacity, selectedDayTimes, duration]);


  useEffect(() => {
    const now = moment(start);
    const end = moment(data.selectedDates.end);
    const selectedDate = now.format("YYYY-MM-DD");
    const endDate = end.format("YYYY-MM-DD");
    const totalSlots = getDaySlots();
    const { close = 0 } = selectedDayTimes || {};
    const [ lastElement = {} ] = totalSlots.slice(-1);
    const { time = 0 }= lastElement;
    const durationUpdate = close - time;
    if ( durationUpdate < duration ) {
      totalSlots.pop();
    }
    if (selectedDate === currentDateTime.currentDate && endDate === currentDateTime.currentDate) {
      const currentHourInMinutes = currentDateTime.currentHour * 60 + currentDateTime.currentMinutes;
      const updatedSlots = totalSlots.filter((slot: any) => {
        if (slot.time > currentHourInMinutes)
          return slot;
      });
      setSlots(updatedSlots);
    } else {
      setSlots(totalSlots);
    }
    // :TODO, if we need to show no of slots on range.
    // if(slotByDay) {
    //   setSlots(Math.ceil((end - start) / (1000*3600*24))+1);
    // }
    // else {
    //   console.log('getDaySlots()', getDaySlots());
    //   setSlots(getDaySlots());
    // }
  }, [getDaySlots]);
  // console.log('allSlotOrders', allSlotOrders);
  // console.log('slots', slots);
  return {
    allSlotOrders,
    slots
  };
};
