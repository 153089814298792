import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const StyledButton = styled.button`
     font-size: 14px;
     box-shadow: none;
        background: ${({background}) => background || ""};
        color: ${({color})=>color || ""};
        border-color: ${({background}) => background || ""};
        &:hover {
           background:  #fff;
           color: ${({background}) => background || ""};
           border-color: ${({background}) => background || ""};
          
        }
        &:focus {
           box-shadow: none;
           border-color: ${({background}) => background || ""};
        }
     
     &.Button-disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
     }
`;

export const Calender = styled.div`
    margin-bottom: 20px;
     .Polaris-DatePicker__Day--selected {
        background: ${({background}) => background || ""};
        color: ${({color})=> color || ""};
        border-color: ${({background}) => background || ""};
      }
      .Polaris-DatePicker__Day:focus {
        box-shadow: none;
      }
`;

export const SlotButton = styled(Button)`
  min-width: 118px;
  &.selected {
   background-color: #28a745;
   color: #fff;
  }
`
