import React, { useState, useCallback } from 'react';
import {
    ActionList,
    OptionList,
    Button,
    Popover,
  } from '@shopify/polaris';

  export const ServiceSelect = (props:any) => {
    console.log(props);
    
    const activator = (
        <Button onClick={props.toggleActive} disclosure>
            Select Service
        </Button>
    );
  
    return (
        <div>
        <Popover
          active={props.active}
          activator={activator}
          onClose={props.toggleActive}
        >
          <OptionList
          onChange={props.callingService}
            options={[
              {
                value: 'physical', label: 'Physical Service' ,
              },
              {
                value: 'virtual', label: 'Virtual Service',
              },
              {
                value: 'both', label: 'Both Services',
              }
            ]}
            selected={props.selectedService}
          />
        </Popover>
      </div>
    );
}