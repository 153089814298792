import { gql } from 'apollo-boost';

export const CREATE_ORDER = gql`
  mutation createBooking($input: CreateBookingInput!) {
    createBooking(input: $input){
      data {
        _id
        duration
        endDate
        endTime
        expiresIn
        personCount
        serviceId
        startDate
        startTime
        status
        token
        updatedAt
        createdAt
      }
      status
      error
    }
}`;