import React, { FC, useState, useCallback } from 'react';
import { DatePicker, Stack } from '@shopify/polaris';
import moment from 'moment';

import { Calender, StyledButton } from "./style";
import { CalendarDates } from '../core';

export const McCalendar: FC<{
  background: any
  color: any,
  onSelection: (values: CalendarDates) => void
}> = ({ onSelection, background, color}) => {
  const initialDates = {
    start: new Date(),
    end: new Date()
  };
  const [{ month, year }, setDate] = useState({
    month: moment().month(),
    year: moment().year()
  });

  const [selectedDates, setSelectedDates] = useState(initialDates);

  const onChangeSelected = (values: CalendarDates) => {
    setSelectedDates(values);
  };

  const handleMonthChange = useCallback(
    (mm, yy) => setDate({ month: mm, year: yy }),
    []
  );

  const previousDate = () => {
    let dateObj = new Date();
    dateObj.setDate(dateObj.getDate() - 1);
    return dateObj;
  }

  return (
    <>
      <Calender background={background} color={color}>
          <DatePicker
            month={month}
            year={year}
            onChange={onChangeSelected}
            onMonthChange={handleMonthChange}
            selected={selectedDates}
            // allowRange={true}
            disableDatesBefore={previousDate()}
          />
      </Calender>
      <Stack distribution="equalSpacing">
          <Stack.Item>
            <StyledButton onClick={() => setSelectedDates(initialDates)} className="Polaris-Button">Reset</StyledButton>
          </Stack.Item>
          <Stack.Item>
            <StyledButton 
              background={background}
              color={color}
              onClick={() => onSelection(selectedDates)}
              className="Polaris-Button Polaris-Button--primary">Apply</StyledButton>
          </Stack.Item>
      </Stack>
    </>
  );
};
