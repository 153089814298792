import { v4 } from 'uuid';
import moment from 'moment';

export const getUuid = () => {
  return v4();
};

export const dateToIso = (date: Date) => {
  return moment(date).toISOString();
};

export const getTime = (time: number, sDate: any) => {
  const hours = `${Math.floor(time / 60)}`;
  const minutes = `${time % 60 ? time % 60 : '00'}`;
  const selectedDate = moment(sDate);

  const date = moment({
    y: selectedDate.year(),
    M: selectedDate.month(),
    d: selectedDate.date(),
    h: +hours,
    minute: +minutes
    // offset: utcOffset
  }); // .zone(new Date().getTimezoneOffset()); //.toISOString(true);

  const zoneTime = moment(date).utcOffset("+05:30").format('YYYY-MM-DD HH:mm');
  // const zoneTime = date.tz('America/New_York').format('YYYY-MM-DD HH:mm');
  console.log('dateeeeee', zoneTime, 'oofset', date.format('YYYY-MM-DD HH:mm'), 'truee', zoneTime);
  // console.log('dateeeeee', date);

  return date.format('YYYY-MM-DD HH:mm');//date.toISOString(true);
};
export const getDate = (date: Date) => {
  const selectedDate = moment(date);
  const day = moment({
    y: selectedDate.year(),
    M: selectedDate.month(),
    d: selectedDate.date()
    // h: 0,
    // minute: 0
  }).format('YYYY-MM-DD'); //.toISOString(true);

  console.log('getDateeeee', day);
  return day;
};

export const getDateDiff = (startDate: Date, endDate: Date) => {
  let initialDate = moment(startDate);
  let finalDate = moment(endDate);
  let dayDiff = (finalDate.diff(initialDate, 'days') + 1);
  // console.log('gggg', dayDiff)
  return dayDiff  // 1
}

export const getHoursFromMilliSecs = (millisecs: number) => {
  return `${(millisecs / 60 < 10) ? '0' + Math.floor(millisecs / 60) : millisecs / 60}:${millisecs % 60 ? millisecs % 60 : '00'}`;
};

export const getHoursFromMilliSecsUser = (millisecs: number) => {
  return `${(millisecs / 60 < 10) ? '0' + Math.floor(millisecs / 60) : millisecs / 60}.${millisecs % 60 ? millisecs % 60 : '00'}`;
};

export const getSessionMins = (start: string, end: string) => {
  const sTime: any = moment(start).hours();
  const eTime: any = moment(end).hours();

  const diff: any = Math.abs(eTime - sTime) * 60;
  return diff;
};

export const geResponseMapper = (res: any, key = '') => {
  if (!(res && key)) {
    return;
  }

  if (typeof key === "string" && res[key].data) {
    return res[key].data;
  }
  else return;
};

export const getMappedError = (res: any, key: string, data: any) => {
  console.log('resss', res);
  if (!(res && key && res[key] && res[key].status === "error")) {
    return false;
  }
  return res[key].error;
};

export const getCurrentDateTime = () => {
  const now = moment();
  const currentDateTime = now.format('YYYY-MM-DD HH:mm');
  const currentTime = now.format("HH:mm");
  const currentDate = now.format("YYYY-MM-DD");
  const currentHour = now.hour();
  const currentMinutes = now.minutes();
  return {
    currentDate,
    currentDateTime,
    currentHour,
    currentMinutes,
    currentTime,
  }
}