import React from 'react';
import { ToastContainer } from 'react-toastify';
import { ApolloProvider } from '@apollo/react-hooks';
import {
  Switch,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';
import { AppProvider } from '@shopify/polaris';
import { client } from './apollo/client/';
import enTranslations from '@shopify/polaris/locales/en.json';
import { Provider as StyletronProvider, DebugEngine } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import { appRoutes } from './core';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';


const debug =
  process.env.NODE_ENV === "production" ? void 0 : new DebugEngine();

// 1. Create a client engine instance
const engine = new Styletron();

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <AppProvider i18n={enTranslations}>
        <StyletronProvider value={engine} debug={debug} debugAfterHydration>
          <Router>
            <Switch>
              {appRoutes}
              <Redirect path="/" to="/onboard" />
            </Switch>
          </Router>
          <ToastContainer />
        </StyletronProvider>
      </AppProvider>
    </ApolloProvider>
  );
};

export default App;
