
import React, {useState} from 'react';
import { TimezonePicker } from "baseui/timezonepicker";
import { SIZE } from 'baseui/input';
import {LightTheme, DarkTheme, ThemeProvider} from 'baseui';

const THEME = {
    light: 'light',
    dark: 'dark',
  };

export const TimeZone = ({value,onChangeTimeHandler}:any) => {

    return (
    <ThemeProvider theme={LightTheme}>
      <TimezonePicker
        value={value}
        size={SIZE.compact}
        date={new Date()}
        onChange={onChangeTimeHandler}
      />
    </ThemeProvider>
    );
};