
import * as actions from './actions';
export const initialState = {
  movies: null,
  isLoggedIn: false
};

// export interface State {
//   movies: AMovies | null;
//   user: AUser;
// }


export const appReducer = (state: any = initialState, action: any) => {
  console.log('hereeee', action);
  if(action.type === actions.USER_MOVIES_SUCCESS) {
    console.log('m in in tthis blocklkfald');
    return {
      ...state,
        isLoggedIn: true,
        movies: action.res
    };
  }

  if(action.type === actions.USER_NOT_SIGN_IN) {
    return {
      ...state,
        isLoggedIn: false,
        movies: []
    }
  }

  if(action.type === 'ADD_NEW_MOVIE') {
    return {
      ...state,
        isLoggedIn: true,
        movies: [...state.user.movies, action.res]
    };
  }

  if(action.type === 'REMOVE_FAV_MOVIE') {
    return {
      ...state,
        isLoggedIn: true,
        movies: state.user.movies.filter((movie: any) => (movie.id !== action.res.id && movie.movieType === 'FAVORITE'))
    };
  }

  return state;
}