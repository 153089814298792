import React from 'react';
import { Spinner } from '@shopify/polaris';

import { LoaderContainer } from './styles';

export const Loader = ({isFullPage = false}: { isFullPage: boolean }) => {
  const className = isFullPage ? 'full-page' : 'spinner';
  return (
    <LoaderContainer>
      <div className={className}>
        <Spinner accessibilityLabel="Spinner" size="large" color="teal" />
      </div>
    </LoaderContainer>
  );
};