import { ApolloClient, InMemoryCache, HttpLink } from 'apollo-boost';
import { createHttpLink } from 'apollo-link-http';
// import { setContext } from 'apollo-link-context';

const cache = new InMemoryCache({
  addTypename: false,
  dataIdFromObject: o => o.id,
});

const defaultApolloOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
}

const link = new createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER,
  // credentials: 'include', //<--  if domain is different if same then 'same-origin'
});

export const client = new ApolloClient({
  headers: {
    'Content-Type': 'application/graphql',
  },
  cache,
  link,
  queryDeduplication: false,
  defaultOptions: defaultApolloOptions,
});
