import React, { useState, useRef, FC, useEffect, FormEvent } from 'react';
import { Modal, Stack, Spinner } from '@shopify/polaris';
import moment from 'moment';

import { SlotButton, StyledButton } from "../../pages/style";

import {
  getDate, getTime, useSlots, TimeSlotsStyles,
  CalendarDates, Order, ProductAsService, Slot, Banner, SpinnerStyle
} from '../';

interface Props {
  loading: boolean;
  error: any; // ApolloError | undefined;
  active: boolean;
  orderLoading: boolean,
  background: any,
  color:any,
  size: string;
  service: ProductAsService;
  orders: Order[];
  selectedDates: CalendarDates;
  onAction: (args: any, capacity: number) => void;
  onClose: () => void;
}

export const TimeSlots: FC<Props> = ({ loading, orderLoading, error, service, selectedDates, orders, ...props }: any) => {
  const { active, background, color, onClose } = props;
  const [bookingActivation, setBookingActivation] = useState<boolean>(true);
  const [selectedTime, setSelectedTime] = useState<string>();
  const [isSelected, setSelected] = useState<boolean>(false);
  const state = useRef({
    slots: [{ time: 0, capacity: 0 }]
  });
  const [slots, setSlots] = useState(state.current.slots);
  const [inputVal, setInputVal] = useState<number>(1);

  state.current = useSlots({
    service,
    selectedDates,
    orders
  });

  useEffect(() => {
    setSlots(state.current.slots);
  }, [state.current.slots]);

  const onSubmit = () => {
    setInputVal(0);
    const time = ((moment(selectedTime).hours() * 60) + moment(selectedTime).minutes()) + service.duration;
    const endTime = getTime(time, selectedTime);
    props.onAction({ selectedTime, startDate: getDate(selectedDates.start), endTime }, inputVal);
  };

  const onClickHandler = function(timeSelected: number, index: any) {
    const date = getTime(timeSelected, selectedDates.start);
    setSelected(index);
    setBookingActivation(!inputVal);
    setSelectedTime(date);
  };

  useEffect(()=> {
    if(!active) {
      setSelected(false)
      setBookingActivation(true)
    }
  },[active])

  const slotsList = ()=> {
    if(loading || orderLoading) {
      return (
        <Stack.Item fill>
          <SpinnerStyle>                  
            <Spinner accessibilityLabel="Spinner example" size="large" />
          </SpinnerStyle>
        </Stack.Item>
      );
    }
    return (
      slots.length !== 0 ? <TimeSlotsStyles>
        {/* <Form.Control
          size="sm"
          type="text"
          placeholder="search and book capacity"
          onChange={inputHandler}
        /> */}
        <Stack distribution="center">
          {slots.map((slot: Slot, index: any) => {
            const hr = Math.floor(slot.time / 60);
            const min = slot.time % 60;
            const { capacity } = slot;
            const noSlots = capacity === 0 || capacity < 1;
              return (
                  <SlotButton
                    className={isSelected === index ? "selected": ""}
                    key={slot.time}
                    size="lg"
                    variant={noSlots ? 'outline-danger' : 'outline-success'}
                    onClick={()=> onClickHandler(slot.time, index)}
                    disabled={noSlots}>
                    {hr < 10 ? '0' + hr : hr} :{' '}
                    {min < 10 ? '0' + min : min}
                    <br />
                    <span>{capacity} slots available</span>
                  </SlotButton>
              );
            
          })}
          {
            // :TODO set error in parent component, on each modal opening..
            // (error) && <Banner status={'critical'} title={`Your request cannot be completed at this stage.`}/>
          }
        </Stack>
      </TimeSlotsStyles> : <Banner status="warning" title="Service is not available on this day" />
    )
  }


  return (
    < Modal
      open={props.active}
      onClose={onClose}
      loading={loading}
      title={`Booking Availability for ${
        selectedDates
          ? moment(selectedDates.start).format('ll')
          : ''
        }`
      }
    >
      <Modal.Section>
       {slotsList()}
      </Modal.Section>
      <div className="footer-modal Polaris-Stack--distributionEqualSpacing Polaris-Modal-Footer">
      <StyledButton onClick={onClose} className="Polaris-Button" >Cancel</StyledButton>
      <StyledButton 
              background={background}
              color={color}
              onClick={onSubmit}
              disabled={bookingActivation}
              className={`Polaris-Button Polaris-Button--primary ${ bookingActivation ? "Button-disabled": ""}`}>Book your slot</StyledButton>
      </div>
    </Modal >
  );
};
