import { gql } from 'apollo-boost';

export const GET_PRODUCT = gql`
  query getService($input: ProductById!) {
    getService(input: $input){
      error
      data {
        _id
        type
        capacity
        duration
        hour{
          sun{
            open
            close
          }
          mon{
            close
            open
          }
          tue{
            close
            open
          }
          wed{
            close
            open
          }
          thu{
            open
            close
          }
          fri{
            open
            close
          }
          sat{
            open
            close
          }
        }
      }
      status
      error
    }
}`;


export const GET_ALL_PRODUCT = gql`
   query getProductsService {
  getProductsService{
      error
      data {
        name
        vendorName
        createdAt
      }
      status
    }
}`;